import { QueryClient } from "@tanstack/react-query"
import createFetchClient from "openapi-fetch"
import createClient from "openapi-react-query"

import { paths } from "@/types/api"

const apiFetchClient = createFetchClient<paths>({
  baseUrl: `${import.meta.env.VITE_WEB_URL}/api`,
  credentials: "include"
})

const queryClient = new QueryClient()

const apiQueryClient = createClient(apiFetchClient)

export { queryClient, apiFetchClient, apiQueryClient }
